
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"6facee2678a6c4d7c124a6f7f11d5f3e367cae05":"getBundles","9ae7d9a8806e543999baf90dc8ea0fbac4cf38e1":"getAddonsbyBundle","b7f5df50a3874b7aa43baeee615f730de243a4f0":"getBundleByHandle"} */ export var getBundles = createServerReference("6facee2678a6c4d7c124a6f7f11d5f3e367cae05");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var getBundleByHandle = createServerReference("b7f5df50a3874b7aa43baeee615f730de243a4f0");
export var getAddonsbyBundle = createServerReference("9ae7d9a8806e543999baf90dc8ea0fbac4cf38e1");

