'use client';
import { BuilderComponent, useIsPreviewing } from '@builder.io/react';
import { builder } from '@builder.io/sdk';
import { affiliateCookies } from 'app/actions/affiliate.actions';
import DefaultErrorPage from 'next/error';
import { ComponentProps, useEffect } from 'react';
import '../builder-registry';

type BuilderPageProps = ComponentProps<typeof BuilderComponent>;

// Builder Public API Key set in .env file
builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);

export function RenderBuilderContent({ content, model }: BuilderPageProps) {
  useEffect(() => {
    const affiliateObj = content?.data?.affiliatePartner?.value?.data;
    affiliateCookies(affiliateObj);
  }, []);
  // Call the useIsPreviewing hook to determine if
  // the page is being previewed in Builder
  const isPreviewing = useIsPreviewing();
  // If "content" has a value or the page is being previewed in Builder,
  // render the BuilderComponent with the specified content and model props.
  if (content || isPreviewing) {
    return <BuilderComponent content={content} model={model} context={{affiliateData: content?.data?.affiliatePartner?.value?.data}}/>;
  }
  // If the "content" is falsy and the page is
  // not being previewed in Builder, render the
  // DefaultErrorPage with a 404.
  return <DefaultErrorPage statusCode={404} />;
}
