import Link from "next/link";
import { cn } from "utils/cn";

interface ButtonGreenProps {
    className?: string;
    href: string;
    children: string | JSX.Element | JSX.Element[]
}

export function ButtonGreen({className, href, children}: ButtonGreenProps) {
    return (
        <Link href={href} className={cn('px-6 flex items-center flex-nowrap phone-button bg-fp-green w-fit hover:bg-white hover:border-fp-green hover:border-2 border-2 border-fp-green min-w-24 min-h-10 rounded-md text-white hover:text-fp-green', className)}>
            {children}
        </Link>
    )

}