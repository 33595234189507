import Link from 'next/link';

interface PromotionBannerProps {
    text: string;
    policy: string;
}

export const PromotionBanner = (props: PromotionBannerProps) => {
    const { text, policy } = props;
  return (
    <div className="container mx-auto !px-0">
      <div className="flex w-full flex-col items-center justify-between md:flex-row">
        <div className="py:5 px-6 py-1 text-white">
          <h2 className="text-center text-[18px] font-medium md:text-left">
            <Link href="/shop">{text}</Link>
          </h2>
        </div>
        <div className="mr-10 flex flex-col py-3 text-white md:py-2">
          <div className="flex items-center pr-3">
            <form>
              <input type="text" name="email" placeholder="Enter your email address" />
            </form>
            <button className="ml-3 underline text-nowrap">
              Get Offer
            </button>
          </div>
            <div className="mt-1 text-[10px]"
              dangerouslySetInnerHTML={{
                __html: policy
              }}
            />
        </div>
      </div>
    </div>
  );
};
