'use client';

import { Modal } from 'flowbite-react';
import { useState } from 'react';

interface ButtonModalProps {
  text?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
  variant: 'primary' | 'secondary' | 'home' | 'link';
  children?: React.ReactNode;
}

export function ButtonModal(props: ButtonModalProps) {
  const { text, size, variant, children } = props;
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      {variant === 'primary' && (
        <button
          onClick={openModal}
          data-modal-target="crud-modal"
          data-modal-toggle="crud-modal"
          className="radius mt-4 flex min-h-10 items-center justify-center rounded-md border-2 border-fp-green bg-fp-green px-8 text-center text-white hover:border-2 hover:border-fp-green hover:bg-white hover:text-fp-green"
        >
          {text}
        </button>
      )}

      {variant === 'secondary' && (
        <button
          onClick={openModal}
          className="mt-4 rounded-lg border-[1px] border-fp-dark-gray px-6 bg-white hover:bg-fp-green py-3 text-sm font-medium hover:border-fp-green hover:text-white"
        >
          {text}
        </button>
      )}

      {variant === 'home' && (
        <button
          onClick={openModal}
          className="rounded-md border-2 px-6 py-2 text-sm bg-white text-fp-dark-gray hover:border-fp-green hover:bg-fp-green hover:text-white"
        >
          {text}
        </button>
      )}

      {variant === 'link' && (
        <p className="text-fp-green underline" rel="noopener noreferrer" onClick={openModal}>
          {text}
        </p>
      )}

      <Modal show={isOpen} size={size} dismissible onClose={() => setIsOpen(false)}>
        <Modal.Header className='!absolute right-0 border-none z-10'/>
        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
}
