'use client'

interface BuilderState {
  context: {
    affiliateData?: {
      phoneNumber?: string;
      // Add other affiliate data properties if needed
    };
  };
}

export function AffiliatePhone(props: { defaultPhone?: string, builderState?: BuilderState }) {
  const { defaultPhone } = props;  
  const phoneNumber = props.builderState?.context?.affiliateData?.phoneNumber || defaultPhone || '833-606-4477';

  return <span>{phoneNumber}</span>;
}
