'use client';
import Image from 'next/image';
import { useEffect } from 'react';

export function Ratings() {
  useEffect(() => {
    setTimeout(function () {
      var id = 'b532637b';
      var script = document.getElementById('pre' + id);
      if (!document.getElementById(id) && script) {
        var loader = document.createElement('script');
        loader.id = id;
        loader.src = '//widgets.reviews.rhino-reviews.com/assets/w.unmin.js';
        script?.parentNode?.insertBefore(loader, script);
      }
    }, 0);
  }, []);
  return (
    <div className="container fp-gray mx-auto mb-12 flex flex-col items-center">
      <div className="flex flex-col items-center">
        <img
          src="https://cdn.builder.io/api/v1/image/assets%2F25b27bb6bc614c2d9723f754b73aae70%2F82c76457fc5c497094690cad56db127c?format=webp"
          alt="Frontpoint Icon"
        />
        <h2>What our clients say</h2>
      </div>
      <div></div>
      <script id="preb532637b"></script>

      <div className="mt-4 flex w-full flex-col items-center justify-center">
        <h5 className="text-center text-2xl lg:text-nowrap">
          Rating based on combined reviews from Google, Trustpilot, Consumer Affairs and Best
          Company.
        </h5>
        <div className="mt-4 flex w-full flex-row items-center justify-center gap-10">
          <a
            target="_blank"
            href="https://www.google.com/search?q=google+reviews+frontpoint&rlz=1C1GCEB_enUS865US865&oq=google+&gs_lcrp=EgZjaHJvbWUqCAgAEEUYJxg7MggIABBFGCcYOzIOCAEQRRgnGDsYgAQYigUyEwgCEC4YgwEYxwEYsQMY0QMYgAQyBggDEEUYPDIGCAQQRRhBMgYIBRBFGEEyBggGEEUYQTIGCAcQRRg80gEINTg1MWowa"
          >
            <Image
              src="https://cdn.shopify.com/s/files/1/0626/1646/9558/files/logo_google.webp?v=1728026849"
              alt="Rhino Reviews"
              width={100}
              height={100}
            />
          </a>
          <a target="_blank" href="https://www.trustpilot.com/review/www.frontpointsecurity.com">
            <Image
              src="https://cdn.shopify.com/s/files/1/0626/1646/9558/files/trustpilot_logo.webp?v=1728026849"
              alt="Rhino Reviews"
              width={250}
              height={10}
            />
          </a>
          <a
            target="_blank"
            href="https://www.consumeraffairs.com/homeowners/frontpoint-security.html"
            className="mt-2"
          >
            <Image
              src="https://cdn.shopify.com/s/files/1/0626/1646/9558/files/ca_logo.webp?v=1728026849"
              alt="Rhino Reviews"
              width={260}
              height={35}
            />
          </a>
          <a target="_blank" href="https://bestcompany.com/home-security/company/frontpoint">
            <Image
              src="https://cdn.shopify.com/s/files/1/0626/1646/9558/files/combo-mark-horizontal.webp?v=1728026849"
              alt="Rhino Reviews"
              width={240}
              height={35}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
