'use client'
import { ButtonModal } from 'components/buttonModal/ButtonModal';
import Image from 'next/image';

interface BannerProps {
  title: string;
  description?: string;
  backgroundImage?: string;
  buttonMessage?: string;
  useDialog?: boolean;
  buttonLink?: string;
  children?: React.ReactNode;
}

export function BenefitCard(props: BannerProps) {
  const { title, description, backgroundImage, buttonMessage, useDialog, buttonLink, children } =
    props;
  return (
    <div className="radius flex h-[20rem] flex-col items-center rounded-md text-white md:h-[30rem]">
      <div className="relative w-full">
        <div className="absolute w-full">
          <Image
            className="h-[20rem] rounded-lg md:h-[30rem]"
            width={500}
            height={500}
            src={backgroundImage || ''}
            alt={`${title} banner`}
            style={{ objectFit: 'cover', objectPosition: 'center center', width: '100%' }}
          />
        </div>
      </div>
      <div className="z-10 flex w-full flex-col items-center px-8 py-10 text-center md:py-16">
        <h2 className="text-lg md:text-2xl font-bold">{title}</h2>
        <div
          className="text-md mt-4 font-bold leading-5"
          dangerouslySetInnerHTML={{
            __html: description || ''
          }}
        />

        {!useDialog && !!buttonLink && (
          <div className="mt-4 flex justify-center">
            <a
              className="rounded-md border-2 px-6 py-2 text-sm bg-white text-fp-dark-gray hover:bg-fp-green hover:text-white hover:border-fp-green hover:text-fp-white"
              href={buttonLink}
            >
              {buttonMessage}
            </a>
          </div>
        )}

        {!!useDialog && (
          <div className="mt-4 flex justify-center">
            <ButtonModal size="4xl" variant="home" text={buttonMessage}>
              <div className="flex w-full items-center justify-center">{children}</div>
            </ButtonModal>
          </div>
        )}
      </div>
    </div>
  );
}
