'use client'
import { BuilderBlocks } from "@builder.io/react";
import { Carousel, CustomFlowbiteTheme } from "flowbite-react";
import React from "react";

interface BannerProps {    
  children: React.ReactNode[];
  slideInterval: number;
  slide: boolean;
  indicators: boolean;
  builderBlock : {
      id: string;
      children: React.ReactNode[];
  }
}

export function MainBanner(props: BannerProps) {
  const {children, slideInterval, slide, builderBlock, indicators} = props;

  return (
    <div>
        <Carousel leftControl={<LeftControl/>} rightControl={<RightControl/>} slide={slide} slideInterval={slideInterval} indicators={indicators} theme={customTheme.carousel}>
          {children && !!children.length? children.map((item, index) => (
            <div key={index} className="relative min-h-[300px] md:max-h-[300px] md:items-center lg:max-h-[400px] w-full">
              {item}
            </div>
          )): 
            <div className="relative w-full">
              <BuilderBlocks
                  parentElementId={builderBlock.id}
                  blocks={builderBlock.children}
                  dataPath="this.children"
                />
            </div>
          }
        </Carousel>
    </div>
  );
}

interface ControlProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

function LeftControl(props: ControlProps) {
const { className, style, onClick } = props;
return (
  <div className="z-30 cursor-pointer text-white" onClick={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="size-12 block"
    >
      <path
        fillRule="evenodd"
        d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);
}
function RightControl(props: ControlProps) {
const { className, style, onClick } = props;
return (
  <div onClick={onClick} className="z-30 cursor-pointer text-white">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="size-12 block"
    >
      <path
        fillRule="evenodd"
        d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
        clipRule="evenodd"
      />
    </svg>
  </div>
)}

const customTheme: CustomFlowbiteTheme = {
  carousel: {
    "root": {
        "base": "relative h-full w-full",
        "leftControl": "absolute left-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none",
        "rightControl": "absolute right-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none"
    },
  "indicators": {
    "active": {
      "off": "bg-white/50 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800",
      "on": "bg-white dark:bg-gray-800"
    },
    "base": "h-3 w-3 rounded-full",
    "wrapper": "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
  },
  "item": {
    "base": "absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
    "wrapper": {
      "off": "w-full flex-shrink-0 transform cursor-default snap-center",
      "on": "w-full flex-shrink-0 transform cursor-grab snap-center"
    }
  },
  "control": {
    "base": "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10",
    "icon": "h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6"
  },
  "scrollContainer": {
    "base": "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth",
    "snap": "snap-x"
  }
}};