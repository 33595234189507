interface RequestQuoteWizardStepProps {
  stepId: string;
  variant: string;
  title?: string;
  description?: string;
  buttons: Array<{
    value: string;
    text: string;
  }>;
  form: any;
  setForm: (value: any, changeStep: boolean) => void;
}

export function RequestQuoteWizardStep(props: RequestQuoteWizardStepProps) {
  const { stepId, variant, title, description, buttons, form, setForm } = props;

  const setFormValue = (value: string) => {
    setForm({ ...form, [stepId]: value }, false);
  };

  const setFormValueAndChange = (value: string) => {
    setForm({ ...form, [stepId]: value }, true);
  };

  const isSelected = (value: string) => {
    const formValue: any = form[stepId];
    if (formValue === value) {
      return 'border-gray-900 border-[2px] bg-teal-500 text-white';
    }
    return 'border-fp-green border-[1px] hover:border-fp-green hover:text-fp-green';
  };

  return (
    <div className="w-full text-center">
      {variant === 'default' && (
        <div className="md:px-20">
          <div>
            <p className="text-[20px] uppercase">{title}</p>
            <p className="mt-4 text-[20px] md:text-[40px] font-light">{description}</p>
          </div>
          {buttons.length && (
            <div className="mt-10 flex items-center justify-center gap-4">
              {buttons.map((btn) => {
                return (
                  <button
                    onClick={() => setFormValue(btn.value)}
                    className={`${isSelected(btn.value)} w-[160px] rounded px-2 text-[14px] font-medium`}
                  >
                    <div dangerouslySetInnerHTML={{ __html: btn.text }} />
                  </button>
                );
              })}
            </div>
          )}
        </div>
      )}

      {variant === 'lifecare' && (
        <>
          <div className="text-left">
            <p className="text-[22px] font-bold text-fp-green">{title}</p>
            <p className="mt-4 text-[14px] font-light">{description}</p>
          </div>
          {buttons.length && (
            <div className="mt-2 flex flex-col items-center justify-center gap-4">
              {buttons.map((btn) => {
                return (
                  <button
                    onClick={() => setFormValueAndChange(btn.value)}
                    className={`w-full bg-fp-sand rounded-2xl px-4 py-2 text-[12px] text-left`}
                  >
                    <div dangerouslySetInnerHTML={{ __html: btn.text }} />
                  </button>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}
