"use client";

import Prose from "components/prose";
import { Accordion } from "flowbite-react";

interface AccordionItemProps {
    accordionItems: AccordionItem[];
    header: string;
    className?: string;
    proseclassName?: string;
}

type AccordionItem = {
    title: string;
    content: string;
}

export function AccordionGeneric(props: AccordionItemProps) {
    const {accordionItems, header, className, proseclassName} = props;
  return (
        <div className="bg-fp-light-gray [&_h2]:text-lg mt-4">
            {header && <Prose html={header} /> }
            <Accordion collapseAll>
                {accordionItems?.map((item, index) => (
                    <Accordion.Panel key={index}>
                        <Accordion.Title>
                            {item.title}
                        </Accordion.Title>
                        <Accordion.Content>
                            <Prose html={item.content} className={proseclassName}/>
                        </Accordion.Content>
                    </Accordion.Panel>
                ))}
            </Accordion>
        </div>
    );
}
