import { ButtonModal } from '../buttonModal/ButtonModal';

interface PackageItem {
  quantity: string;
  title: string;
  description: string;
}

interface PackageItemsListProps {
  heading: string;
  items: PackageItem[];
}

export function PackageItemsList({ heading, items }: PackageItemsListProps) {
  return (
    <div>
      <h3>{heading}</h3>
      <ul className="list-decimal inline">
        {items.map((item, index) => (
          <li className="list-decimal inline" key={index}>
            <strong>{item.quantity}&nbsp;x&nbsp;</strong>
            <ButtonModal text={item.title} variant="link" size="lg">
              <h2>{item.title}</h2>
            </ButtonModal>
            <p>&nbsp;&mdash;&nbsp;{item.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}